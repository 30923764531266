<template>
  <a-modal v-model="modalShow" :footer="null"
           :width="width"
           class="modal"
           @cancel="cancel"
           :title="title && title[language] ? title[language] : ''">
    <a-form :form="form" @submit="handleSubmit">
      <div class="modal__container">
        <slot name="body" />
      </div>
      <div class="block block__right block__align-center" v-if="!hideButton">
        <div>
          <a-button @click="cancel" v-if="showCancel">
            {{ cancelText[language] }}
          </a-button>
        </div>
        <div class="component__margin-left">
          <a-button type="primary" html-type="submit">
            {{ okText[language] }}
          </a-button>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'ModalForm',
  data() {
    return {
      form: this.$form.createForm(this, { name: this.name }),
      modalShow: false,
    };
  },
  props: {
    show: Boolean,
    title: Object,
    name: {
      type: String,
      default: 'modal',
    },
    okText: {
      type: Object,
      default() {
        return {
          ru: 'Добавить',
          en: 'Add',
        };
      },
    },
    ok: {
      type: Function,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: Object,
      default() {
        return {
          ru: 'Отменить',
          en: 'Cancel',
        };
      },
    },
  },
  watch: {
    show: function (val) {
      this.modalShow = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.ok) {
            this.ok(values, this.form).then((item) => {
              if (!item.notClose) {
                this.$emit('ok', values);
                this.cancel();
              }
            }).catch(() => {});
          } else {
            this.$emit('ok', values);
            this.cancel();
          }
        }
      });
    },
    cancel() {
      this.$emit('cancel');
      this.modalShow = false;
    },
  },
  mounted() {
    this.$emit('setForm', this.form);
  },
  created() {
    this.modalShow = this.show;
  },
};
</script>
